<template>
  <ag-card-container
    title="Annuler une déclaration de rebut en stock"
    fluid
    no-content-padding
  >
    <v-container fluid class="py-0">
      <filter-selection v-model="partNumberSelection" :loading="loadingData" />
    </v-container>
    <ag-card-container
      v-if="resultPartList.length"
      :search.sync="search"
      title="Résultats"
      fluid
      no-content-padding
    >
      <template #left>
        <v-btn
          :disabled="!resultPartList.length || loadingData"
          color="success"
          fab
          x-small
          :loading="loadingExport"
          @click="exportExcel"
        >
          <v-icon>file_download</v-icon>
        </v-btn>
      </template>
      <part-list
        :value="resultPartList"
        :selected.sync="selectedpartList"
        :loading="loadingData"
        :search="search"
      />

      <ag-float-button-container>
        <ag-confirm-dialog
          v-if="selectedpartList.length"
          title="Confirmation"
          :message="changeStatusMessage"
          cancel-label="Annuler"
          confirm-label="Valider"
          validation-btn-color="primary"
          @confirm="changeStatus"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :loading="loadingChangeStatus"
              color="success"
              fab
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>done</v-icon>
              <v-chip
                x-small
                class="px-1 float-chip font-weight-black"
                color="white"
                text-color="success"
                v-text="selectedpartList.length"
              />
            </v-btn>
          </template>
        </ag-confirm-dialog>
      </ag-float-button-container>
    </ag-card-container>
  </ag-card-container>
</template>

<script>
import AgConfirmDialog from 'src/components/common/AgConfirmDialog'
import AgCardContainer from 'src/components/common/AgCardContainer'
import AgFloatButtonContainer from 'src/components/common/AgFloatButtonContainer'
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import { createExcelFile } from 'src/services/drawExcelService'
import FilterSelection from '../components/FilterSelection'
import PartList from '../components/PartList'

export default {
  name: 'UnsetRejectedPartFromStockContainerVue',
  components: {
    AgConfirmDialog,
    AgCardContainer,
    AgFloatButtonContainer,
    FilterSelection,
    PartList,
  },
  data: () => ({
    partNumberSelection: null,
    resultPartList: [],
    selectedpartList: [],
    loadingData: false,
    loadingChangeStatus: false,
    loadingExport: false,
    search: '',
    showRejectionSelection: false,
  }),
  computed: {
    changeStatusMessage () {
      const count = this.selectedpartList.length
      return `Souhaitez-vous annuler les déclaration de ${count} pièce(s)`
    },
  },
  watch: {
    partNumberSelection: {
      immediate: true,
      handler (value) {
        if (value) {
          this.fetchData(value)
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showSuccessNotification: 'showSuccessNotification',
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData (partNumber) {
      try {
        this.loadingData = true
        this.resultPartList = await Repositories.part.getList({
          partNumber: partNumber._id,
          rejected: true,
          use: false,
          'declarationData.from': 'Stock',
        })
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
    async changeStatus () {
      try {
        this.loadingChangeStatus = true
        const count = this.selectedpartList.length
        await Repositories.part.unsetRejectedPartFromStock({
          idList: this.selectedpartList.map(select => select._id),
        })
        this.showSuccessNotification(`Vous avez annuler la déclaration de rebut de ${count} pièce(s)`)
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingChangeStatus = false
        this.selectedpartList = []
        this.showRejectionSelection = false
        this.fetchData(this.partNumberSelection)
      }
    },
    async exportExcel () {
      try {
        this.loadingExport = true
        const exportConfig = {
          columns: [
            { header: 'Numéro de série', key: 'serialNumber' },
            { header: 'Référence', key: 'partNumber' },
            { header: 'Type de référence', key: 'partType' },
            { header: 'Numéro d\'OF', key: 'batch' },
            { header: 'Consommé', key: 'use' },
            { header: 'Derogéé', key: 'exception' },
            { header: 'Retouchée', key: 'retouched' },
            { header: 'Rebutée', key: 'rejected' },
            { header: 'Detruite', key: 'destroyed' },
            { header: 'Id', key: '_id' },
          ],
          view: [{ state: 'frozen', xSplit: 1, ySplit: 1 }],
          sheetName: 'Liste des pièces',
        }
        const data = this.resultPartList.map(item => ({
          _id: item._id,
          serialNumber: item.serialNumber,
          partNumber: item.partNumber.name,
          partType: item.partType.name,
          batch: item.batch.number,
          exception: item.exception,
          use: item.use,
          retouched: item.retouched,
          rejected: item.rejected,
          destroyed: item.destroyed,
        }))
        await createExcelFile([{ dataToExport: data, config: exportConfig }], 'Liste des pièces')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingExport = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.float-chip {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 12px;
}
</style>
